<template>
    <b-card class="st-section">
        <div class="st-section-header">
           <div class="d-flex justify-content-between w-100">
                <p>{{ $t('DASHBOARD.TOP_USERS.HEADER') }}</p>
                <div>
                    <span class="label label-xl label-inline label-light-dark">
                            {{ $t('DASHBOARD.APPLICATIONS_STATUS.PERIOD') }}
                    </span>
                    <span class="ml-4"><i class="fas fa-ellipsis-h font-size-h4"></i></span>
                </div>
            </div>
        </div>
        <div class="st-section-body">
            <template v-for="(item, i) in usersList">
                <div
                    class="d-flex align-items-center flex-wrap mb-5"
                    :key="i"
                >
                    <span class="mr-4 text-black-50">{{i+1}}</span>
                    <div class="symbol symbol-60 symbol-light-success mr-5">
                        <span v-if="item.photo">
                            <b-avatar :src="item.photo" size="2.5rem"></b-avatar>
                        </span>
                        <span v-else class="font-size-h5 font-weight-bold">
                            <b-avatar :src="defaultAvatar" size="2.5rem"></b-avatar>
                        </span>
                    </div>
                    <div class="d-flex flex-column flex-grow-1 mr-2">
                        <a
                            href="#"
                            class="font-weight-normal font-size-base text-dark-75 text-hover-primary mb-1"
                        >
                            {{ item.title }}
                        </a>
                    </div>
                    <span class="font-size-base text-primary py-1 my-lg-0 my-2">
                        {{ item.alt }}
                    </span>
                </div>
            </template>
        </div>
    </b-card>
</template>

<script>
import { mapState } from 'vuex';
export default {
    name: "DashboardTopUsers",
    data() {
        return {
            usersList: [
                {
                    title: "Dana Pop",
                    alt: "30",
                    photo: "media/users/100_4.jpg"
                },
                {
                    title: "Mihai Andreescu",
                    alt: "28",
                    photo: ""
                },
                {
                    title: "Vlad Geaorgescu",
                    alt: "26",
                    photo: ""
                },
                {
                    title: "Malin Quist",
                    alt: "17",
                    photo: ""
                },
                {
                    title: "Darren Adams",
                    alt: "11",
                    photo: ""
                },
            ],
        }
    },
    computed: {
        ...mapState({
            defaultAvatar: state => state.shared.defaultAvatar,
        }),
    },
}

</script>
